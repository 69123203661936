import React from "react"
import { Icon } from "@iconify/react"
import fileAlt from "@iconify/icons-fa-solid/file-alt"
import phoneIcon from "@iconify/icons-fa-solid/phone"
import commentsIcon from "@iconify/icons-fa-regular/comments"
import mapMarkerAlt from "@iconify/icons-fa-solid/map-marker-alt"

import "./application_box.scss"

function ApplicationBoxIcon(props) {
  if (props.text === "網上表格") {
    return <Icon icon={fileAlt} style={{ padding: "0 10px" }} />
  } else if (props.text === "電話查詢") {
    return <Icon icon={phoneIcon} style={{ padding: "0 10px" }} />
  } else if (props.text === "短信查詢") {
    return <Icon icon={commentsIcon} style={{ padding: "0 10px" }} />
  } else if (props.text === "親臨本行") {
    return <Icon icon={mapMarkerAlt} style={{ padding: "0 10px" }} />
  } else {
    return <span />
  }
}

export default function ApplicationBox(props) {
  return (
    <div className="application_section">
      <ApplicationBoxIcon text={props.title} />
      <div className="application_box_content">
        <h3>
          {props.title}
          <ApplicationBoxIcon text={props.title} />
        </h3>
        <h4>{props.content}</h4>
        <h5>{props.subContent}</h5>
      </div>
    </div>
  )
}

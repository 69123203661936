import React from "react"
import { useForm } from "react-hook-form"
import { Icon } from "@iconify/react"
import fileAlt from "@iconify/icons-fa-solid/file-alt"
import "./application_form.scss"

function InputTitle(props) {
  let required
  props.required === "true" ? (required = "*") : (required = "")
  return (
    <div className="input_title">
      {props.title}
      <span className="input_required">{required}</span>
    </div>
  )
}

export default function Form() {
  const { register, handleSubmit, errors } = useForm() // initialize the hook
  const onSubmit = async data => {
    if (data.property_type === undefined) {
      data.property_type = "n/a"
    }
    if (data.address === undefined) {
      data.address = "n/a"
    }
    if (data.comment === undefined) {
      data.comment = "n/a"
    }
    const res = await fetch(
      `/send_email?name=${data.name}&phone=${data.phone}&email=${data.email}&gender=${data.gender}&mortgage_type=${data.mortgage_type}&mortgage_amount=${data.mortgage_amount}&property_type=${data.property_type}&address=${data.address}&comment=${data.comment}&terms=${data.terms}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    if (res.json()) {
      alert("你的表格已被發送")
      window.location.reload()
    }
  }

  return (
    <div id="application_form">
      <div className="application_title">
        <h3>網上表格</h3>
        <Icon
          icon={fileAlt}
          style={{
            color: "#6D6D6D",
            fontSize: "39px",
            padding: "0 10px",
          }}
        />
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="question_i">
          <InputTitle title="貸款種類" required="true" />
          <select
            name="mortgage_type"
            defaultValue={"DEFAULT"}
            ref={register({ required: true })}
          >
            <option value="DEFAULT" disabled>
              - Select -
            </option>
            <option value="物業一按">物業一按</option>
            <option value="物業二按">物業二按</option>
            <option value="居屋業主貸款">居屋業主貸款</option>
            <option value="單邊按揭">單邊按揭</option>
            <option value="建期貸款、丁屋發展">建期貸款、丁屋發展</option>
          </select>
          <br />
          {errors.mortgage_type && (
            <span style={{ color: "red" }}>請選擇貸款種類</span>
          )}
        </div>
        <div className="question_i">
          <InputTitle title="申請貸款額(港幣$)" required="true" />
          <input
            type="number"
            name="mortgage_amount"
            ref={register({ required: true })}
          />
          <br />
          {errors.mortgage_amount && (
            <span className="warning">請選擇申請貸款額</span>
          )}
        </div>
        <div className="question_r">
          <InputTitle title="稱謂" required="true" />
          <label htmlFor="先生">
            <input
              type="radio"
              name="gender"
              value="先生"
              ref={register({ required: true })}
            />
            先生
          </label>
          <span className="spacer" />
          <label htmlFor="小姐">
            <input
              type="radio"
              name="gender"
              value="小姐"
              ref={register({ required: true })}
            />
            小姐
          </label>
          <br />
          {errors.gender && <span className="warning">請選擇稱謂</span>}
        </div>
        <div className="question_i">
          <InputTitle title="姓名" required="true" />
          <input name="name" ref={register({ required: true })} />
          <br />
          {errors.name && <span className="warning">請選輸入姓名</span>}
        </div>
        <div className="question_i">
          <InputTitle title="電話" required="true" />
          <input name="phone" type="tel" ref={register({ required: true })} />
          <br />
          {errors.phone && <span className="warning">請選輸入電話號碼</span>}
        </div>
        <div className="question_i">
          <InputTitle title="電郵" required="true" />
          <input name="email" type="email" ref={register({ required: true })} />
          <br />
          {errors.email && <span className="warning">請選輸入聯絡電郵</span>}
        </div>
        <div className="question_r">
          <InputTitle title="業權種類" required="false" />
          <label htmlFor="單名">
            <input
              type="radio"
              name="property_type"
              value="單名"
              ref={register({ required: false })}
            />
            單名
          </label>
          <span className="spacer" />
          <label htmlFor="聯名">
            <input
              type="radio"
              name="property_type"
              value="聯名"
              ref={register({ required: false })}
            />
            聯名
          </label>
          <span className="spacer" />
          <label htmlFor="公司名">
            <input
              type="radio"
              name="property_type"
              value="公司名"
              ref={register({ required: false })}
            />
            公司名
          </label>
        </div>
        <div className="question_i">
          <InputTitle title="物業地址" required="false" />
          <input name="address" ref={register({ required: false })} />
        </div>
        <div className="question_i">
          <InputTitle title="留言" required="false" />
          <input
            name="comment"
            style={{ height: "200px" }}
            ref={register({ required: false })}
          />
        </div>
        <div className="question_r">
          <InputTitle title="聲明" required="true" />
          <label htmlFor="terms">
            <input
              type="radio"
              name="terms"
              value="accept"
              ref={register({ required: true })}
            />
            <span className="spacer" />
            本人／吾等聲明本申請表內所載一切資料均屬真實，正確及完整。本人明白開心按揭服務有限公司按照私隱政策處理本人提交資料。本人同意開心按揭服務有限公司會向信貸資料服務機構索取本人之信貸報告，用作是次及日後貸款審批用途。按揭金額及條款視乎物業種類及情況而定。本公司保留隨時終止貸款優惠之權利及貸款之最終批核權。
          </label>
          <br />
          {errors.terms && <span className="warning">必須同意聲明</span>}
        </div>
        <input type="submit" value="遞交" className="submit_button" />
      </form>
    </div>
  )
}

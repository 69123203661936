import React from "react"
import { Helmet } from "react-helmet"
import Navbar from "../components/navbar"
import Footer from "../components/footer"
import Form from "../components/application_form"
import Box from "../components/application_box"
import Header from "../components/header"

export default function Application() {
  return (
    <div className="container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>申請辦法 | 開心按揭服務有限公司</title>
      </Helmet>
      <Navbar />
      <div className="content">
        <Header text="application_header" />
        <div id="application">
          <section id="form">
            <Form />
          </section>
          <div id="phone" className="separator">
            <section
              data-sal="zoom-in"
              data-sal-duration="500"
              data-sal-easing="ease"
            >
              <Box
                title="電話查詢"
                content="服務熱線: 2640 6789"
                subContent="客戶可以透過電話形式咨詢，批核完成後馬上提取貸款，簡單快捷。"
              />
            </section>
          </div>
          <div id="message" className="separator">
            <section
              data-sal="zoom-in"
              data-sal-duration="500"
              data-sal-easing="ease"
            >
              <Box
                title="短信查詢"
                content="短訊專線: 5489 9534"
                subContent="客戶可以透過電話短信或WhatsApp咨詢，本公司職員經初步批核後便會與客戶聯絡安排提取貸款。"
              />
            </section>
          </div>
          <div id="visit" className="separator">
            <section
              data-sal="zoom-in"
              data-sal-duration="500"
              data-sal-easing="ease"
            >
              <Box
                title="親臨本行"
                content="地址: 九龍灣常悅道9號企業廣場一期二座11樓1109C室"
                subContent="辦公時間 : 10:30 am – 6:30 pm (星期一至五) "
              />
            </section>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
